<template>
	<div class="products-table">
    <div class="empty" v-if="topProdutos.length === 0">
      <p>Carregando...</p>
    </div>   
    <div v-if="topProdutos.length > 0">
      <div class="products__list">
        <!-- Loop Product -->
        <ProductItem 
          v-for="produto in topProdutos"
          :key="produto.productId"
          :produto="produto" />
        <!-- Loop Product -->
      </div>
    </div>
	</div>
</template>

<script>
export default {
  computed: {
    topProdutos() {
      return this.$store.state.products.viewData.slice(0, 5);
    }
  }
};
</script>

<style lang="scss">
.panel {
  height: 100%;

	.product {
		&__content {
			border-top: 1px solid $lightGray;
			display: flex;
			margin: 2.5vh 0 0;
			padding: 2.5vh 0 0;
		}

		&__description {
			width: 75%;
			display: flex;
			justify-content: space-between;

			> div {
				display: flex;
				flex-direction: column;
				width: 65%;
				&:last-child {
					width: 30%;
				}
			}
		}

		&__infos {
			&.--qtd {
				align-items: center;
				display: flex;

				p {
					@include font-size(1.7);
					margin-left: 10px;
				}
			}

			label {
				color: $cinza-interno;
				font-size: 12px;
				font-weight: 400;
				margin-bottom: 5px;
			}

			p {
				color: $cor-escura;
				font-size: 12px;
				font-weight: 600;
				line-height: 1.2;
				margin-bottom: 10px;
			}
		}
	}


	.product__boxImage {
		display: flex;
		align-items: center;
		margin-right: 1.5vw;
	}

	.product__figure {
		width: 5.2vw;
		height: 5.2vw;
		border-radius: 50%;
		box-sizing: border-box;
		border: 8px solid $lightGray;
		overflow: hidden;
		box-shadow: 0px 5px 10px rgba(2, 107, 126, 0.1);

		img {
			width: 100%;
		}
	}

	.product__name {
		min-width: 5.1rem;
		font-family: $font__main;
		@include font-size(0.82);
		color: $gray;
		display: block;
		line-height: 0.8vw;
	}

	.product__info {
		font-family: $font__main;
		@include font-size(0.76);
		color: $dark;
	}

	.product__qtd {
		font-family: $font__main;
		color: $dark;
		margin-left: 0;
		line-height: 2rem; 
	}

	.product__highlight {
		display: flex;
	}

	.products__list li:first-child {
		border: 0;

		.product__figure {
			border-color: #FFC400;
			
		}

		.product__boxImage {
			position: relative;

			&::before {
				content: '';
				width: 2.2vw;
				height: 2.9vw;
				position: absolute;
				z-index: 1;
				top: 3.5vw;
				right: 0;
				background: url('~@/assets/images/icones/icon-award.svg') no-repeat center;
				background-size: cover;
			}
		}
	}
}
</style>
