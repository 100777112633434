<template>
  <div class="table">
    {{ items }}
    <b-table :items="items" :fields="fields"></b-table>
  </div>
</template>

<script>
// import { numberToReal } from "@/utils/money";
export default {
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Produto",
          sortable: true,
        },
        {
          key: "brandName",
          label: "Marca",
          sortable: true,
        },
        {
          key: "categoriesNames",
          label: "Categoria",
          sortable: true,
        },
        {
          key: "qtd_vendidos",
          label: "Quantidade vendido",
          sortable: true,
        },
      ],
    };
  },
  methods: {},
  computed: {
    items() {
      return this.$store.state.products.viewData;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
}
</style>
